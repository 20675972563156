@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #61674e;
  
}
.--third-color{
  background-color: #61674edb;
  box-shadow: 0px 0px 7px black;
}
.--third-color-no-shadow{
  background-color: #61674edb;
  /* box-shadow: 0px 0px 7px black; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dark-room {
 
  background: #ff018300;
  background: radial-gradient(at center, #ff018300, #010101);
}
.light-out {
  background-color: #010101aa;
}
.light-flare {
  background: rgba(255, 255, 255, 0.56);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.41) 5%,
    rgba(0, 212, 255, 0) 21%
  );
}
.lam-off {
  background-color: rgb(54, 56, 56);
}
.lam-on {
  background-color: rgb(54, 56, 56);
  border-bottom: #888888 2px solid;
}
.gasoek-one-regular {
  font-family: "Belanosima", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fadein {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  transform-origin: bottom;
}
.card:hover > .fadein {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.navigation {
 
  margin:  auto ; 
  top: 0;
  bottom: 0;
  height: fit-content;
}
@media (max-width: 700px) {
  .subtext{
    width: 80%;
  }
  .wrapper-mobile {
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
  }

  .navigation {
    bottom: 1rem;
    top: auto; 
    flex-direction: row;
    margin:  auto ; 
    left: 0;
    right: 0;
    width: fit-content;
  }

   h1{
    font-size: 2rem !important;
  }

  .main p{
    font-size: 1rem;
    padding: 0 1rem;
    text-align: center;
  }
  .divider{
    display: none;
  }

  .navigation-bubles{
    flex-direction:  row !important;
  }
}
